import cx from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import * as global from "../../styles/global.module.scss";
import { ButtonCta, LinkCta } from "../Cta";
import * as styles from "./index.module.scss";

interface Props {
  onClick?: () => void;
  data: { [key: string]: any };
  centered?: boolean;
  pagePath: string;
}

export const GuideCard: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    onClick = () => {
      return;
    },
    data,
    centered = false,
  } = props;

  const dataImg =
    data.image && data.image.localFile.childImageSharp.gatsbyImageData
      ? data.image.localFile.childImageSharp.gatsbyImageData
      : null;
  return (
    <div
      className={cx(styles.guideItemContent, { [styles.centered]: centered })}
    >
      {dataImg ? (
        <div className={styles.imgContainer}>
          <GatsbyImage image={dataImg} alt={data.title} />
        </div>
      ) : null}
      <div>
        <div className={styles.infoHeaderContainer}>
          <h2 className={cx(styles.title, global.title)}>{data.title}</h2>
          <span className={cx(styles.guidePrice, global.text)}>
            Prix : - €
          </span>
        </div>
        <p className={cx(styles.subtitle, global.subtitle)}>
          {data.description}
        </p>
        <div>
          <ButtonCta
            btnClassname={styles.btnGuideCta}
            wordingClassname={styles.cardGuideCta}
            disabled
            type="button"
            label="Plus disponible"
            onClick={() => {
              onClick();
            }}
          />
        </div>
      </div>
    </div>
  );
};
