import cx from "classnames";
import { graphql } from "gatsby";
import React, { FC } from "react";
import { GuideCard } from "../../components/guideCard";
import SEO from "../../components/seo";
import * as global from "../../styles/global.module.scss";
import * as stylesCheckout from "../../styles/pages/checkout/guide-personnalise.module.scss";
const { routes } = require("../../routes");

interface Props {
  location: Location;
  data: { [key: string]: any };
}

export const CheckoutGuideCustomQuery = graphql`
  query {
    strapiProduct(SKU: { eq: "CSTGUCSTM01" }) {
      id
      slug
      price
      title
      description
      SKU
      fileguid
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;

const CheckoutGuideCustomPage: FC<Props> = (props: Props) => {
  const {
    location,
    data: { strapiProduct },
  } = props;

  return (
    <>
      <SEO
        meta={[
          {
            name: "og:url",
            content: location.href,
          },
        ]}
        title="Constant - Finaliser ma commande"
      />
      <div className={cx(global.pageWrapper)}>
        <div className={stylesCheckout.presentationContainer}>
          <h2 className={cx(stylesCheckout.title, global.title)}>
            Vous voilà à la dernière étape pour recevoir votre guide
            personnalisé&nbsp;!
          </h2>
          <div className={cx(stylesCheckout.text, global.text)}>
            <p>
              Si vous êtes ici, c'est que vous m'avez donné toutes les infos
              nécessaires pour la création de votre guide et que je vous ai
              envoyé le lien vers cette page par email.
            </p>
            <p>
              Après avoir procédé au paiement, vous n'aurez plus qu'à chiller
              tranquillement (environ 48h) en attendant de recevoir votre
              guide&nbsp;!
            </p>

            <p>
              Vous avez un doute&nbsp;? N'hésitez pas à me contacter&nbsp;!{" "}
              <br />
              <a
                href={`mailto:${process.env.GATSBY_EMAIL}`}
                className={global.link}
                target="_blank"
                rel="noreferrer noopener"
              >
                {process.env.GATSBY_EMAIL}
              </a>
            </p>
          </div>
          <div className={stylesCheckout.cardContainer}>
            <GuideCard
              data={strapiProduct}
              centered={true}
              pagePath={routes.app.checkoutGuidePerso.path}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutGuideCustomPage;
